import styled from "styled-components";
import { BREAKPOINTS } from "../consts";

export default styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: ${(props) => (props.floatInMiddle ? "center" : "auto")};
  > * {
    width: ${(props) => (props.fullWidth ? "100%" : "50%")};
  }

  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    flex-direction: column;
    justify-content: center;
    > * {
      width: 100%;
    }
  }
`;
