import styled from "styled-components";
import { BREAKPOINTS } from "../consts";
import splitFlexBox from "./split-flex-box";

export default styled(splitFlexBox)`
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "42rem")};
  align-items: center;
  h3, .h3 {
    margin: 0;
  }
  p {
    margin: 1.8rem 0;
  }

  .image-container {
    width: ${(props) =>
      props.imagePercentage ? props.imagePercentage : "42%"};
  }

  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    text-align: center;

    .image-container {
      order: 1;
      width: 84%;
      margin: 0 0 3rem 0;
    }

    .text-container {
      order: 2;
    }
  }

  ${(props) =>
    props.alignItems ? `align-items: ${props.alignItems}` : "initial"};
`;
