import * as React from "react";
import Layout from "../components/layout";
import Block from "../components/block";
import styled from "styled-components";
import SmallContentBox from "../components/small-content-box";
import Footer from "../components/footer";
import { BREAKPOINTS } from "../consts";
import { useMediaQuery } from "react-responsive";
import { StaticImage } from "gatsby-plugin-image";
import { Gradient, GradientWapper } from "../components/gradients";
import Decor from "../components/decor";


const SECURITY_ITEM_SIZE = 64;

const Page = styled.div``;
const Header = styled.div`
  width: 35rem;
  padding: 6rem 0 0;
  text-align: center;
  h1 {
    width: 26rem;
    margin: 0 auto;
  }
  p {
    margin: 3rem 0 0;
  }

  @media screen and (max-width: ${BREAKPOINTS["tablet"]}px) {
    width: 100%;
    padding: 3rem 0;
    h1 {
      width: 80%;
      margin: 0 auto;
    }
  }
`;
const SecurityItem = styled.div`
  text-align: center;
  h2 {
    margin: 0.8rem 0;
  }
  &:nth-child(2) {
    padding: 0 6rem;
  }
  @media screen and (max-width: ${BREAKPOINTS["tablet"]}px) {
    width: 92%;
    padding: 0 10px !important; /* looks bad in some resolutions without some x-padding */
    margin: 1rem auto;
  }
`;
const TransactWithConfidence = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  > p {
    margin-bottom: 4rem;
    max-width: 700px;
    text-align: center;
  }
  div {
    text-align: left;
    h2 {
      margin: 0;
    }
    p {
      margin: 0.4rem 0 1.2rem 0;
    }
  }

  @media screen and (max-width: ${BREAKPOINTS["tablet"]}px) {
    text-align: center;
    > p {
      margin-bottom: 2rem;
    }
    div {
      text-align: center;
    }
  }
`;

const RememberContainer = styled.div`
  margin-bottom: 4rem;
  h1 {
    text-align: center;
    margin-bottom: 4rem;
  }
  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    h1 {
      margin-bottom: 2rem;
    }
  }
`;
const RememberItem = styled.div`
  padding: 0px 1rem;
  text-align: center;
  .number {
    border: 2px solid #4961ea;
    border-radius: 100px;
    display: inline-block;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1rem;
  }
  p {
    color: white;
    opacity: 1;
  }
`;

const DecorSettings = {
  desktop: [
    {
      colour: "blue",
      size: 25,
      left: 210,
      top: -100,
    },
    {
      colour: "white",
      size: 15,
      left: -138,
      top: -120,
    },
    {
      colour: "white",
      size: 11,
      left: 160,
      top: 50,
    },
    {
      colour: "purple",
      size: 22,
      left: -270,
      top: 60,
    },
  ],
  mobile: [
    {
      colour: "blue",
      size: 14,
      left: 110,
      top: -70,
    },
    {
      colour: "white",
      size: 10,
      left: -109,
      top: -60,
    },
    {
      colour: "white",
      size: 7,
      left: 100,
      top: 20,
    },
    {
      colour: "purple",
      size: 13,
      left: -130,
      top: 20,
    },
  ],
};

const SecurityPage = () => {
  const [isMobile, setIsMobile] = React.useState(false);
  const mediaQuery = useMediaQuery({
    query: `(max-width: ${BREAKPOINTS["mobile"]}px)`,
  });
  
  React.useEffect(() => {
    setIsMobile(mediaQuery);
  }, [mediaQuery, isMobile]);

  return (
    <Page>
      <Layout title={"Security"} description={"Bifrost Wallet is self custody secured. Strong encryption, data minimization and independently audited."}>
        <GradientWapper>
          <Gradient position="topLeft" color="blue" />
          <Block>
            <Header>
              <Decor
                settings={
                  isMobile ? DecorSettings.mobile : DecorSettings.desktop
                }
              >
                <h1>Self Custody Secured</h1>
              </Decor>
              <p>
                The best way to secure your crypto assets is by controlling your
                own keys. We aim to make this as simple and non
                obtrusive as possible. Your keys, your crypto.
              </p>
            </Header>
          </Block>
        </GradientWapper>

        <GradientWapper>
          <Gradient position="bottomRight" color="blue" />
          <Block noPadding={isMobile}>
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <SecurityItem>
                <div className="icon">
                  <StaticImage
                    src="../images/StrongEncryption.png"
                    quality={100}
                    alt="Strong Encryption"
                    height={SECURITY_ITEM_SIZE}
                    placeholder="none"
                  />
                </div>
                <h2>Strong Encryption</h2>
                <p>
                  Your keys are stored in the iOS keystore or Android keychain,
                  in encrypted form on your own device.
                </p>
              </SecurityItem>
              <SecurityItem>
                <div className="icon">
                  <StaticImage
                    src="../images/DataMinimization.png"
                    quality={100}
                    alt="Data Minimization"
                    height={SECURITY_ITEM_SIZE}
                    placeholder="none"
                  />
                </div>
                <h2>Data Minimization</h2>
                <p>
                  Bifrost Wallet doesn't track any personal identifiable
                  information, your account addresses or asset balances.
                </p>
              </SecurityItem>
              <SecurityItem>
                <div className="icon">
                  <StaticImage
                    src="../images/IndependentlyAudited.png"
                    quality={100}
                    alt="Independently Audited"
                    height={SECURITY_ITEM_SIZE}
                    placeholder="none"
                  />
                </div>
                <h2>Independently Audited</h2>
                <p>
                  Our code has been audited by FYEO and Cure53, well-respected code
                  audit and penetration testing firms.
                </p>
              </SecurityItem>
            </div>
          </Block>
        </GradientWapper>

        <Block>
          <GradientWapper>
            <Gradient position="bottomLeft" color="purple" />

            <TransactWithConfidence>
              <h1>Transact with confidence</h1>
              <p>
                Maintain full control of your assets with insights into who and
                what you're interacting with.
              </p>

              {isMobile && (
                 <div
                 style={{
                   width: "92%",
                   margin: "0 auto"
                 }}
               >
                <StaticImage
                  src="../images/TransactMobile.png"
                  quality={100}
                  placeholder="none"
                  alt="Transact with confidence"
                />
                </div>
              )}

              <SmallContentBox imagePercentage={isMobile ? "0%" : "45%"} alignItems={"baseline"}>
                <div>
                  <h2>Transaction Insights</h2>
                  <p>
                    Bifrost Wallet shows comprehensive transaction details.
                    Always know what you're signing.
                  </p>
                  <h2>Address Book</h2>
                  <p>
                    Save and name any address. No more confusing cryptocurrency
                    addresses.
                  </p>
                  <h2>Extensive Testing</h2>
                  <p>
                    Every new version of Bifrost Wallet goes through an
                    extensive review and testing procedure.
                  </p>
                </div>
                {!isMobile && (
                  <div className="image-container">
                    <StaticImage
                      src="../images/TransactDesktop.png"
                      quality={100}
                      placeholder="none"
                      alt="Transact with confidence"
                      imgStyle={{ paddingTop: "1rem" }}
                    />
                  </div>
                )}
              </SmallContentBox>
            </TransactWithConfidence>
          </GradientWapper>
        </Block>

        <Block>
          <RememberContainer>
            <h1>Remember!</h1>
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <RememberItem>
                <div className="number">1</div>
                <p>Never share your secret recovery phrase</p>
              </RememberItem>
              <RememberItem>
                <div className="number">2</div>
                <p>We’ll never email you or ask for personal data</p>
              </RememberItem>
              <RememberItem>
                <div className="number">3</div>
                <p>Always review all transaction details</p>
              </RememberItem>
            </div>
          </RememberContainer>
        </Block>
      </Layout>
      <Footer />
    </Page>
  );
};

export default SecurityPage;
